<template>
  <div class="cg_main" v-loading="btnLoading"
       element-loading-text="拼命计算中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div style="width: 100%;height: 5px;background: #EFEFEF; "></div>
    <div class="cg_main_son">
      <div style=" display: flow-root;margin-bottom: 25px;margin-top: 25px;">

        <div class="box">
          <div class="contain">

            <div class="topTitle">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item><router-link :to="{path:'/'}">首页</router-link></el-breadcrumb-item>
                <el-breadcrumb-item>冷热通道需求表配置</el-breadcrumb-item>
              </el-breadcrumb>
              <img src="../image/step.png" />
            </div>



            <el-form label-width="170px" label-position="right" size="mini">
              <div ref ="lessonTableImg">
                <div v-show="jgShow1">
                  <!-- 第一行 -->
                  <div class="onePart">
                    <div class="titleFix">
                      <div class="bar"></div>
                      <div class="title">柜体与通道</div>
                    </div>
                    <div class="twoPart">
                      <div class="subtext">通道类型</div>
                      <el-form-item label-width="50px">
                        <el-select
                            class="inputWidth"
                            v-model="formInline.passType1"
                        >
                          <el-option label="冷通道" value="冷通道"></el-option>
                          <el-option label="热通道" value="热通道"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label-width="50px">
                        <el-select
                            class="inputWidth"
                            v-model="formInline.passType2"

                        >
                          <el-option label="单列左" value="单列左"></el-option>
                          <el-option label="单列右" value="单列右"></el-option>
                          <el-option label="双列" value="双列"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>

                    <div>
                      <el-form-item label-width="130px" class="tdkd" label="通道宽度(MM)">
                        <el-select
                            class="inputWidth"
                            v-model="formInline.passWidth"

                        >
                          <el-option label="1200" value="1200"></el-option>
                          <el-option label="1800" value="1800"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="clear"></div>
                  </div>
                  <!-- 第二行 -->
                  <div class="threePart">

                    <div>
                      <div class="subtext jglx">机柜类型</div>
                      <el-form-item label-width="50px">
                        <el-select
                            class="inputWidth"
                            v-model="formInline.cabinetType"
                            @change="changeColor"

                        >
                          <el-option label="X3" value="X3"></el-option>
                          <el-option label="X5" value="X5"></el-option>
                          <el-option label="X7" value="X7"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="color">
                      <span class="subtext">灯带颜色：</span>
                      <el-select
                          class="inputWidth"
                          v-model="formInline.lightColor"
                          :disabled="lightShow"
                      >
                        <el-option label="无" value="无"></el-option>
                        <el-option label="蓝光" value="蓝光"></el-option>
                        <el-option label="RGB" value="RGB"></el-option>
                      </el-select>
                    </div>

                    <div>
                      <el-form-item
                          label-width="120px"
                          class="subtext"
                          label="M型走线槽/柜"
                      >
                        <el-select
                            class="inputWidth"
                            v-model="formInline.mCableCount"

                        >
                          <el-option label="0" value="0"></el-option>
                          <el-option label="1" value="1"></el-option>
                          <el-option label="2" value="2"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="clear"></div>
                  </div>
                  <!-- 第三行 -->
                  <div class="topbg">
                    <div class="tableTop">
                      <div>宽(MM)</div>
                      <div>深(MM)</div>
                      <div>U数</div>
                      <div>单柜功率(KW)</div>
                      <div>数量(台)</div>
                      <div>托盘/柜</div>
                      <div>托架/柜</div>
                      <div>1U盲板/柜</div>
                      <div>2U盲板/柜</div>
                    </div>
                  </div>
                  <!-- 第四行 -->
                  <div class="tablecc">
                    <div class="subTitle">柜体1尺寸：</div>
                    <div class="subTitle">柜体2尺寸：</div>
                  </div>
                  <div class="tableForm">
                    <el-form-item label-width="0px" class="subTitle">
                      <el-select class="oi" v-model="formInline.cabinet1Width" >
                        <el-option label="600" value="600"></el-option>
                        <el-option label="800" value="800"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label-width="30px">
                      <el-select class="oi" v-model="formInline.cabinet1Depth" >
                        <el-option label="1100" value="1100"></el-option>
                        <el-option label="1200" value="1200"></el-option>
                      </el-select>
                    </el-form-item>

                    <div>
                      <el-form-item label-width="20px">
                        <el-select
                            class="oi"
                            v-model="formInline.cabinet1UCount"

                        >
                          <el-option label="42U" value="42"></el-option>
                          <el-option label="47U" value="47"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label-width="px">
                        <el-select  class="oi" v-model="formInline.cabinet1Power" >
                          <el-option label="0" value="0"></el-option>
                          <el-option label="3" value="3"></el-option>
                          <el-option label="5" value="5"></el-option>
                          <el-option label="8" value="8"></el-option></el-select>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label-width="-0px">
  <!--                      <el-input @blur="sl1Change" class="pi" v-model="formInline.cabinet1Count"></el-input>-->
                        <el-input  class="pi" v-model="formInline.cabinet1Count"></el-input>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label-width="-20px">
                        <el-input class="pi" v-model="formInline.cabinet1Tray"></el-input>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label-width="-40px">
                        <el-input class="pi" v-model="formInline.cabinet1Bracket"></el-input>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label-width="-60px">
                        <el-input class="pi" v-model="formInline.cabinet1Plate1u"></el-input>
                      </el-form-item>
                    </div>

                    <div>
                      <el-form-item label-width="-70px">
                        <el-input class="pi" v-model="formInline.cabinet1Plate2u"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- 第五行 -->

                  <div class="tableForm table2">
                    <el-form-item label-width="0px" class="subTitle">
                      <el-select class="oi" v-model="formInline.cabinet2Width" >
                        <el-option label="600" value="600"></el-option>
                        <el-option label="800" value="800"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label-width="30px">
                      <el-select class="oi" v-model="formInline.cabinet2Depth" >
                        <el-option label="1100" value="1100"></el-option>
                        <el-option label="1200" value="1200"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label-width="20px">
                      <el-select
                          class="oi"
                          v-model="formInline.cabinet2UCount"
                          placeholder="2000(42U)"
                      >
                        <el-option label="42U" value="42"></el-option>
                        <el-option label="47U" value="47"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label-width="20px">
                      <el-select  class="oi" v-model="formInline.cabinet2Power" placeholder="0">
                        <el-option label="0" value="0"></el-option>
                        <el-option label="3" value="3"></el-option>
                        <el-option label="5" value="5"></el-option>
                        <el-option label="8" value="8"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label-width="40px">
                      <el-input  class="pi" v-model="formInline.cabinet2Count"></el-input>
                    </el-form-item>

                    <el-form-item label-width="49px">
                      <el-input class="pi" v-model="formInline.cabinet2Bracket"></el-input>
                    </el-form-item>

                    <el-form-item label-width="51px">
                      <el-input class="pi" v-model="formInline.cabinet2Tray"></el-input>
                    </el-form-item>

                    <el-form-item label-width="50px">
                      <el-input class="pi" v-model="formInline.cabinet2Plate1u"></el-input>
                    </el-form-item>

                    <el-form-item label-width="60px">
                      <el-input class="pi" v-model="formInline.cabinet2Plate2u"></el-input>
                    </el-form-item>
                    <div class="clear"></div>
                  </div>
                  <!-- 第六行 -->

                  <div class="tdtc">
                    <div class="sixPart">
                      <div>
                        <el-form-item
                            label-width="95px"
                            class="subtext"
                            label="通道天窗："
                        >
                          <el-col :span="21">
                            <el-select
                                v-model="formInline.passSkylight"
                            >
                              <el-option label="直角（手动）" value="直角（手动）"></el-option>
                              <el-option :disabled="zjShow" label="直角（自动）" value="直角（自动）"></el-option>
                              <el-option label="弧形（手动" value="弧形（手动）"></el-option>
                            </el-select>
                          </el-col>
                        </el-form-item>

                        <el-form-item class="subtext" label="热围框高度(mm)：">
                          <el-col :span="19">
                            <el-input v-model="formInline.hotFrameHeight"></el-input>
                          </el-col>
                        </el-form-item>

                        <div class="subtext">机柜顶部至吊顶下方的距离（评估热通道）</div>
                        <div class="clear"></div>
                      </div>
                      <!-- 第七行 -->
                      <div>
                        <div class="subtext">天花板与地面完成面高度（评估冷通道）</div>
                        <div class="floatbox">
                          <div>
                            <div class="thirdtext">
                              机柜顶部至吊顶低部的有效空间，高度：
                            </div>
                            <el-form-item label-width="0px">
                              <el-input
                                  class="spaceheight"
                                  v-model="formInline.heightGap"
                                  @change="highChange"
                              ></el-input>
                            </el-form-item>
                            <div class="thirdtext">
                              高度大于500mm,小于不建议采用通道）。
                            </div>
                            <div class="clear"></div>
                          </div>
                          <div class="lasttext">
                            （700MM以上，采用300支撑高单开天窗，700-500MM之间，采用200支撑高双开天窗）
                          </div>
                        </div>
                        <div class="clear"></div>
                      </div>
                    </div>

                    <!-- 第八行 -->
                    <div>
                      <div class="part8">
                        <el-form-item
                            label-width="178px"
                            class="subtext"
                            label="柱子是否嵌入通道位置:">
                          <el-select
                              v-model="formInline.pillarPosition"
                              @change="zzChange"
                          >
                            <el-option label="无柱子" value="无柱子"></el-option>
                            <el-option label="柱子嵌入柜体后部" value="柱子嵌入柜体后部"></el-option>
                            <el-option label="柱子嵌入柜体中间" value="柱子嵌入柜体中间"></el-option>
                            <el-option label="柱子嵌入柜体前面" value="柱子嵌入柜体前面"></el-option>
                            <el-option label="柱子嵌入通道中间" value="柱子嵌入通道中间"></el-option>

                          </el-select>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item class="subtext" label-width="128px" label="数量:">
                          <el-input class="pi" v-model="formInline.pillarCount"></el-input>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item class="subtext" label-width="128px" label="假柜体尺寸:">
                          <!--<el-input disabled="" class="pi" v-model="formInline.falseCabinet"></el-input>-->
                          <el-select
                              v-model="formInline.falseCabinet"
                              :disabled="zzShow"
                          >
                            <el-option label="无柱子" value="无柱子"></el-option>
                            <el-option label="300" value="300"></el-option>
                            <el-option label="600" value="600"></el-option>
                            <el-option label="800" value="800"></el-option>
                            <el-option label="1200" value="1200"></el-option>

                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="clear"></div>
                    </div>

                    <!-- 第十行 -->
                    <div>
                      <el-form-item label-width="82px" class="subtext" label="移门类型:">
                        <el-col :span="20">
                          <el-select
                              v-model="formInline.slidedoorType"
                              @change="ymChange"
                          >
                            <el-option label="自动平移（标配)" value="自动平移"></el-option>
                            <el-option label="手动推拉" value="手动推拉"></el-option>
                          </el-select>
                        </el-col>
                      </el-form-item>
                      <!--默认-->
                      <el-form-item class="subtext" label="显示屏:">
                        <el-col :span="20">
                          <el-select v-model="formInline.screenSize" >
                            <el-option label="无" value=""></el-option>
                            <el-option label="10屏(标配)" value="10"></el-option>
                            <el-option label="21.5屏" value="21.5"></el-option>
                          </el-select>
                        </el-col>
                      </el-form-item>
                      <div class="clear"></div>
                    </div>
                  </div>
                </div>

                <div v-show="jgShow6">
                  <div class="titlebox">
                    <div class="titleFix">
                      <div class="bar"></div>
                      <div class="title">其它说明</div>
                    </div>
                    <div class="clear"></div>
                    <el-input style="width: 400px !important;"   type="textarea"  class="spaceheight" v-model="formInline.otherRemark2"
                    />
                  </div>
                </div>
              </div>
              <el-form-item>
                <el-button v-show="nextShow" type="primary" @click="onSubmit">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

      </div>
      <div style="margin-bottom: 40px;">

      </div >


    </div>
  </div>
</template>

<script>
  import html2canvas from "html2canvas"
import {
  microdemandAdd,
  projectAdd,
  microdemandChange, microdemandAddhotandcold,
  uploadImg,
} from "@/Api/finance/home";
import { encrypt,decrypt } from "@/Util/auth";
const cityOptions = ['柜体与通道', '配电系统', '制冷系统', '动环监控系统','其它说明','工程安装'];
export default {
  name: "index",
  data() {
    return {
      btnLoading:false,
      lightShow:false,
      jmShow:true,
      rlShow1:false,
      rlShow2:true,
      rlShow3:false,
      nextShow:true,
      jgShow1:true,
      jgShow2:true,
      jgShow3:true,
      jgShow4:true,
      jgShow5:false,
      jgShow6:true,
      formInline:{
        file:'',
        lowTemp:'否',

        "cabinet1Bracket": "2", //柜体1托盘
        "cabinet1Count": "13", //柜体1数量
        "cabinet1Depth": "1200", //柜体1深度
        "cabinet1UCount": "42", //柜体1高度
        "cabinet1Plate1u": "10", //柜体1 1u盲板数量
        "cabinet1Plate2u": "0", //柜体1 2u盲板数量
        "cabinet1Power": "5", //柜体1功率
        "cabinet1Tray": "2", //柜体1托架
        "cabinet1Width": "600", //柜体1宽度
        "cabinet2Bracket": "2",
        "cabinet2Count": "0",
        "cabinet2Depth": "1200",
        "cabinet2UCount": "42",
        "cabinet2Plate1u": "10",
        "cabinet2Plate2u": "0",
        "cabinet2Power": "5",
        "cabinet2Tray": "2",
        "cabinet2Width": "800",
        "cabinetType": "X5", //柜体类型
        "heightGap": "700", //机柜顶部至吊顶底部空间
        "hotFrameHeight": "300", //热围框高度
        "mCableCount": "2", //M型走线槽/柜
        "passPosition": "通道外", //通道内，通道外
        "passSkylight": "直角（手动）", //通道天窗
        "passType1": "冷通道", //通道类型
        "passType2": "双列", //单列 双列
        "passWidth": "1200", //通道宽度
        "pduWithConnect": "是", //pdu是否带工业连接器
        "screenSize": "10", //屏幕大小
        "slidedoorType": "自动平移", //移门类型
        "pillarPosition": "无柱子",
        "pillarCount": "",
        "socket1Type": "国标10A三扁",
        "socket1Count": "8",
        "socket2Type": "国标16A三扁",
        "socket2Count": "4",
        "socketTotal": "12",
        "lightColor": "RGB"
      },
      /*formInline: {
          "passType1": "冷通道",
          "passType2": "单列左",
          "passWidth": "1200",
          "passPosition": "通道内",
          "cabinetType": "X1",
          "mCableCount": "1只",

          "cabinet1Width": "600",
          "cabinet1Depth": "1100",
          "cabinet1Height": "2000(42U)",
          "cabinet1Power": "5",
          "cabinet1Count": "13",
          "cabinet1Bracket": "2",
          "cabinet1Tray": "2",
          "cabinet1Plate1u": "2",
          "cabinet1Plate2u": "2",

          "cabinet2Width": "600",
          "cabinet2Depth": "1100",
          "cabinet2Height": "2000(42U)",
          "cabinet2Power": "5",
          "cabinet2Count": "13",
          "cabinet2Bracket": "2",
          "cabinet2Tray": "2",
          "cabinet2Plate1u": "2",
          "cabinet2Plate2u": "2",

          "passSkylight": "标配直角（手动）",
          "hotFrameHeight": "300",
          "heightGap": "600",

          "pillarPosition": "无柱子",
          "pillarCount": "2",
          "slidedoorType": "自动平移（标配)",
          "screenSize": "10寸屏",

          "conAirSupply": "水平送风", //空调送风方式
          "conRedundance": "N+1系统", //空调冗余系统
          "conType": "行级空调", //空调类型
          "desUpsPowerType": "双路", //UPS输入、输出柜供电方式
          "pduWithConnect": "是", //pdu是否带工业连接器
          "upsRedundanceSystem": "N+1系统", //ups冗余系统
          "upsType": "模块化UPS" //ups类型

      },*/
      checkedCities: ['柜体与通道', '配电系统', '制冷系统', '动环监控系统','其它说明'],
      cities: cityOptions,
      ruleForm: { type: "" },
      ktSelect:true,
      zjShow:true,
      xtShow1:true,
      xtShow2:false,
      xtShow3:false,
      jmdShow:false,
      zzShow:true,

    }
  },
  methods: {
    dataURLtoFile(dataurl, filename){

      let arr = dataurl.split(','),
              mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    toImage(pid) {
      // 手动创建一个 canvas 标签
      const canvas = document.createElement("canvas");
      // 获取父标签，意思是这个标签内的 DOM 元素生成图片
      // lessonTableImg是给截图范围内的父级元素自定义的ref名称
      let canvasBox = this.$refs.lessonTableImg;
      // 获取父级的宽高
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";
      const context = canvas.getContext("2d");
      context.scale(2, 2);
      const options = {
        backgroundColor: "#FFF",  //设置canvas背景图颜色 防止有些图片透明
        canvas: canvas,
        useCORS: true,
      };
      html2canvas(canvasBox, options).then((canvas) => {
        let dataURL= canvas.toDataURL("image/png");
        let file=this.dataURLtoFile(dataURL, "截图");

        let formdata = new FormData();
        formdata.append("file", file);
        formdata.append("pid", pid);
        uploadImg(formdata).then((res) => {
          if (res.code ==200) {
            console.log(res);
            this.$router.push({name:'configurationInfo',query: {id:pid,isR:'i',indextype:'2'}})
          }
        });


        //调用下载
        // this.downloadImage(dataURL);
        // 转为file文件
        // var file = this.dataURLtoFile(dataURL, "封面");
        // var formdata = new FormData();
        // formdata.append("file", file);
        // //file上传到后台
        // upImgResource(formdata).then((res) => {
        //     if (res.code == 0) {
        //         console.log(res);
        //     }
        // });
      });
    },
    gl1Change(value) {

      this.btnLoading = true
      let obj = {
        "cabinet1Count": this.formInline.cabinet1Count, //柜体1数量
        "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
        "cabinet1UCount": this.formInline.cabinet1UCount,
        "cabinet1Power": value, //柜体1功率
        "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
        "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
        "cabinet2Count": this.formInline.cabinet2Count,
        "cabinet2Depth": this.formInline.cabinet2Depth,
        "cabinet2UCount": this.formInline.cabinet2UCount,
        "cabinet2Power": this.formInline.cabinet2Power,
        "cabinet2Width": this.formInline.cabinet2Width,
        "cabinetType": this.formInline.cabinetType, //柜体类型
        "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
        "passType2": this.formInline.passType2, //单列 双列
        "passWidth": this.formInline.passWidth, //通道宽度
        "selectOptionsList": this.formInline.selectOptionsList,
        "desUpsPowerType": this.formInline.desUpsPowerType,
      }
      let ss = encrypt(JSON.stringify(obj))
      microdemandChange(ss).then(res => {
        let dd = JSON.parse(decrypt(res))
        if (dd.code == 200) {

          this.btnLoading = false
          this.$message.success(`修改成功`);
          this.formInline = Object.assign(this.formInline, dd.data,);
          if (this.formInline.upsType == '一体化UPS') {
            this.jmShow = false
          } else {
            this.jmShow = true
          }
        } else {
          this.btnLoading = false
          alert(dd.msg);
        }

      })
    },
    gl2Change(value) {
      this.btnLoading = true
      let obj = {
        "cabinet1Count": this.formInline.cabinet1Count, //柜体1数量
        "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
        "cabinet1UCount": this.formInline.cabinet1UCount,
        "cabinet1Power": this.formInline.cabinet1Power, //柜体1功率
        "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
        "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
        "cabinet2Count": this.formInline.cabinet2Count,
        "cabinet2Depth": this.formInline.cabinet2Depth,
        "cabinet2UCount": this.formInline.cabinet2UCount,
        "cabinet2Power": value,
        "cabinet2Width": this.formInline.cabinet2Width,
        "cabinetType": this.formInline.cabinetType, //柜体类型
        "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
        "passType2": this.formInline.passType2, //单列 双列
        "passWidth": this.formInline.passWidth, //通道宽度
        "selectOptionsList": this.formInline.selectOptionsList,
        "desUpsPowerType": this.formInline.desUpsPowerType,
      }
      let ss = encrypt(JSON.stringify(obj))
      microdemandChange(ss).then(res => {
        let dd = JSON.parse(decrypt(res))
        if (dd.code == 200) {

          this.btnLoading = false
          this.$message.success(`修改成功`);
          this.formInline = Object.assign(this.formInline, dd.data,);

          if (this.formInline.upsType == '一体化UPS') {
            this.jmShow = false
          } else {
            this.jmShow = true
          }
        } else {
          this.btnLoading = false
          alert(dd.msg);
        }

      })
    },
    // sl1Change(value){
    //   this.btnLoading=true
    //   let obj = {
    //     "cabinet1Count": value.target.value, //柜体1数量
    //     "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
    //     "cabinet1UCount": this.formInline.cabinet1UCount,
    //     "cabinet1Power": this.formInline.cabinet1Power, //柜体1功率
    //     "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
    //     "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
    //     "cabinet2Count": this.formInline.cabinet2Count,
    //     "cabinet2Depth": this.formInline.cabinet2Depth,
    //     "cabinet2UCount": this.formInline.cabinet2UCount,
    //     "cabinet2Power": this.formInline.cabinet2Power,
    //     "cabinet2Width": this.formInline.cabinet2Width,
    //     "cabinetType": this.formInline.cabinetType, //柜体类型
    //     "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
    //     "passType2":this.formInline.passType2, //单列 双列
    //     "passWidth":this.formInline.passWidth, //通道宽度
    //     "selectOptionsList": this.formInline.selectOptionsList,
    //     "desUpsPowerType": this.formInline.desUpsPowerType,
    //   }
    //   let ss= encrypt(JSON.stringify(obj))
    //   microdemandChange(ss).then(res => {
    //     let dd= JSON.parse(decrypt(res))
    //     if (dd.code == 200) {
    //
    //       this.btnLoading=false
    //       this.$message.success(`修改成功`);
    //       this.formInline= Object.assign(this.formInline,dd.data,);
    //       if(this.formInline.upsType=='一体化UPS'){
    //         this.jmShow=false
    //       }else {
    //         this.jmShow=true
    //       }
    //     }else {
    //       this.btnLoading=false
    //       alert(dd.msg);
    //     }
    //
    //   })
    // },
    sl1Change(value) {
      this.btnLoading = true
      let obj = {
        "cabinet1Count": value.target.value, //柜体1数量
      }
    },


    sl2Change(value) {
      this.btnLoading = true
      let obj = {

        "cabinet2Count": value.target.value,
      }
    },

    // sl2Change(value){
    //   this.btnLoading=true
    //   let obj = {
    //     "cabinet1Count": this.formInline.cabinet1Count, //柜体1数量
    //     "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
    //     "cabinet1UCount": this.formInline.cabinet1UCount,
    //     "cabinet1Power": this.formInline.cabinet1Power, //柜体1功率
    //     "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
    //     "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
    //     "cabinet2Count": value.target.value,
    //     "cabinet2Depth": this.formInline.cabinet2Depth,
    //     "cabinet2UCount": this.formInline.cabinet2UCount,
    //     "cabinet2Power": this.formInline.cabinet2Power,
    //     "cabinet2Width": this.formInline.cabinet2Width,
    //     "cabinetType": this.formInline.cabinetType, //柜体类型
    //     "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
    //     "passType2":this.formInline.passType2, //单列 双列
    //     "passWidth":this.formInline.passWidth, //通道宽度
    //     "selectOptionsList": this.formInline.selectOptionsList,
    //     "desUpsPowerType": this.formInline.desUpsPowerType,
    //   }
    //   let ss= encrypt(JSON.stringify(obj))
    //   microdemandChange(ss).then(res => {
    //     let dd= JSON.parse(decrypt(res))
    //     if (dd.code == 200) {
    //
    //       this.btnLoading=false
    //       this.$message.success(`修改成功`);
    //       this.formInline= Object.assign(this.formInline,dd.data,);
    //       if(this.formInline.upsType=='一体化UPS'){
    //         this.jmShow=false
    //       }else {
    //         this.jmShow=true
    //       }
    //     }else {
    //       this.btnLoading=false
    //       alert(dd.msg);
    //     }
    //
    //   })
    //
    //
    // },
    dcChnage(value){
      if(value=='32' ){
        this.formInline.batteryCapacity='65'
      }else if(value=='36' ){
        this.formInline.batteryCapacity='100'
      }else if(value=='40' ){
        this.formInline.batteryCapacity='120'
      }else if(value=='44' ){
        this.formInline.batteryCapacity='150'
      }else if(value=='50' ){
        this.formInline.batteryCapacity='200'
      }
    },
    gnChange(value){

      if(value.includes('3D建模')){
        this.jmdShow=true

      }else {
        this.jmdShow=false
      }
    },
    ymChange(value){

      if(value.includes('自动平移')){
        this.formInline.screenSize='10'

      }else {
        this.formInline.screenSize=''
      }
    },
    xtChange(value){
      if(value=='基础动环' ){
        this.xtShow1=true
        this.xtShow2=false
        this.xtShow3=false
      }else if(value=='运维平台' ){
        this.xtShow1=false
        this.xtShow2=true
        this.xtShow3=false
      }else {
        this.xtShow1=false
        this.xtShow2=false
        this.xtShow3=true

      }
    },
    cz1Change(value){
      this.formInline.socketTotal =Number(this.formInline.socket2Count)+Number(value)
    },
    cz2Change(value){
      this.formInline.socketTotal = Number(this.formInline.socket1Count)+Number(value)
    },
    zzChange(value){
      if(value=='无柱子' ){
        this.zzShow=true
      }else {
        this.zzShow=false
      }
    },
    highChange(value){
      if(value>=700 ){
        this.zjShow=false

      }else {
        this.zjShow=true
      }
    },
    changeColor(value){
      if(value=='X7' || value=='X5' ){
        this.formInline.lightColor='无'
        this.lightShow=false
      }else {
        this.lightShow=true
      }
    },
    ryChange(value){
      /*if(value=='2N系统' ){
          this.formInline.desUpsPowerType='双路'
      }else {
          this.formInline.desUpsPowerType='单路'
      }*/
    },
    rlChange(value){
      if(value=='一体化UPS'){
        this.jmShow=false
        this.formInline.upsCapacity='60'
        this.formInline.passPosition='通道内'
        this.rlShow1=true
        this.rlShow2=false
        this.rlShow3=false
      }else if(value=='模块化UPS'){
        this.jmShow=true
        this.formInline.upsCapacity='200'
        this.formInline.passPosition='通道外'
        this.rlShow1=false
        this.rlShow2=true
        this.rlShow3=false
      }else {
        this.jmShow=true
        this.formInline.upsCapacity='200'
        this.formInline.passPosition='通道外'
        this.rlShow1=false
        this.rlShow2=false
        this.rlShow3=true
      }
    },
    ktChange(value){
      if(value=='行级空调'){
        this.formInline.conPosition='通道内'
        this.formInline.conAirSupply='正前送风'
        this.formInline.conCapacity='25'
        this.ktSelect=true
      }else {
        this.formInline.conPosition='通道外'
        this.formInline.conAirSupply='上送风，正面回风'
        this.formInline.conCapacity='70'
        this.ktSelect=false
      }
    },
    handleCheckedCitiesChange(value) {

      if(value.length==0){
        this.nextShow=false
      }else {
        this.nextShow=true
      }
      if(value.includes('柜体与通道')){
        this.jgShow1=true
      }else {
        this.jgShow1=false
      }
      if(value.includes('配电系统')){
        this.jgShow2=true
      }else {
        this.jgShow2=false
      }
      if(value.includes('制冷系统')){
        this.jgShow3=true
      }else {
        this.jgShow3=false
      }
      if(value.includes('动环监控系统')){
        this.jgShow4=true
      }else {
        this.jgShow4=false
      }
      if(value.includes('工程安装')){
        this.jgShow5=true
      }else {
        this.jgShow5=false
      }
      if(value.includes('其它说明')){
        this.jgShow6=true
      }else {
        this.jgShow6=false
      }
      /*this.checkedCities.map((item,index)=>{
          if(value.includes(item)){
              let ss='jgShow'+index
              this.jgShow1=true
          }else {
              this.jgShow1=false
          }
      })*/
    },
    onSubmit(){
      this.btnLoading=true
      console.log(this.formInline)
      let ss= encrypt(JSON.stringify(this.formInline))
      microdemandAddhotandcold(ss).then(res => {
        let dd= JSON.parse(decrypt(res))
        console.log(dd)
        if (dd.code == 200) {
          this.$store.commit('updateFormInline',this.formInline)
          this.toImage(dd.data)

        }else {
          alert(dd.msg);
        }

      }).finally(()=>{
          this.btnLoading=false
        })

    },
    onSubmitOne(){
      this.btnLoading=true
      console.log(this.formInline)
      let ss= encrypt(JSON.stringify(this.formInline))
      projectAdd(ss).then(res => {
        let dd= JSON.parse(decrypt(res))
        console.log(dd)
        if (dd.code == 200) {

          this.$router.push({name:'configurationInfo',query: {id:dd.data}})
        }else {
          alert(dd.msg);
        }

      })
    },
  },
  mounted(){
    // const c= Object.assign(this.b, this.a);
    // console.log(c,'c')
    if(this.$route.query.isR=='i'){
      this.formInline=this.$store.state.formInline
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-loading-spinner{
  position: fixed;
}
.cg_main{
  background: white;
  height: 100%;
  display: flow-root;
  .cg_main_son{
    width: 1200px;
    margin: 0px auto;
    min-height: 499px;
    .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin-bottom: 12px !important;
    }
    .el-input--mini .el-input__inner {
      height: 35px !important;
      line-height: 35px !important;
    }
    .el-input__suffix,
    .el-form-item--mini .el-form-item__label {
      line-height: 35px !important;
    }
    .topTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 907px;
      margin: 23px 0;

      .el-breadcrumb {
        font-size: 16px !important;
      }
    }
    .box {
      width: 100%;
    }
    .contain {
      width: 1326px;
      margin: 0 auto;
    }
    .el-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .mainTitle {
      width: 100%;
      font-size: 20px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
      margin-bottom: 33px;
    }
    .titlebox {
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      height: 70px;
      display: flex;
      align-items: center;
    }
    .titleFix {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 138px;
      float: left;

      .title {
        width: 113px;
        height: 26px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #333333;
        float: left;
      }
      .bar {
        width: 4px;
        height: 26px;
        background: #e72a32;
      }
    }
    .tableTop {
      div {
        width: 150px;
        text-align: center;
      }
    }

    .el-button--primary {
      width: 146px;
      height: 50px;
      margin: 20px auto;
    }
    .topbg {
      width: 1326px;
      height: 50px;
      background: #f5f5f5;
      border: 1px solid #e5e5e5;
      .tableTop {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 1186px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #333333;
        line-height: 50px;
        margin-left: 146px;
        font-weight: bold;
        float: left;
      }
    }

    .subTitle {
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #333333;
      float: left;
      margin-left: 24px;
      line-height: 35px;
    }
    .ltt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      width: 998px;
      float: left;
      font-size: 13px;
      color: #666666;
    }
    .subtext {
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #333333;
      float: left;
      line-height: 35px;
    }
    .bpdsq {
      margin: 10px 36px 0 25px;
    }
    .thirdtext {
      font-size: 16px;
      line-height: 35px;
      color: #333333;
      float: left;
      font-weight: bold;
    }

    .el-form-item__label {
      font-size: 16px !important;
      font-family: Source Han Sans SC;
      font-weight: bold !important;
      color: #333333 !important;
    }
    .borderLine {
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      width: 100%;
      height: 50px;
      padding-top: 13px;
      box-sizing: border-box;
    }
    .tableForm {
      width: 1173px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 29px 0 0 0;
      margin-left: 11px;
    }
    .table2 {
      margin: 20px 0 13px 11px;
    }
    .oi {
      width: 110px !important;
    }
    .oii {
      width: 146px !important;
      margin-right: 33px;
    }
    .oiii {
      width: 169px !important;
      margin-right: 33px;
    }
    .pi {
      width: 80px !important;
    }
    .tableForm {
      .el-form-item {
        width: 150px;
      }
    }
    .tablecc {
      width: 130px;
      height: 100px;
      float: left;
      padding-top: 6px;
      .subTitle {
        line-height: 67px;
      }
    }

    .inputWidth {
      width: 140px;
      float: left;
    }
    .el-form-item {
      float: left;
    }
    .twoPart {
      float: left;
      margin-left: 76px;
      width: 452px;
    }
    .onePart {
      width: 1326px;
      border-bottom: 1px solid #e5e5e5;
    }
    .clear {
      clear: both;
    }
    .threePart {
      width: 1326px;
      padding-top: 12px;
      margin-bottom: 11px;
    }
    .part14 {
      margin: 0 0 !important;
    }
    .color {
      height: 35px;
      line-height: 35px;
      float: left;
      margin-left: 77px;
      margin-right: 134px;
    }

    .outside {
      width: 140px;
      margin-right: 50px;
    }
    .tdkd {
      margin-left: 74px;
    }
    .spaceheight {
      width: 90px !important;
      margin: 0 10px;
    }
    .part11 {
      margin: 20px 0 10px 0;
    }
    .xdc {
      width: 20px;
      height: 78px;
      margin-right: 40px;
      line-height: 32px;
    }
    .floatbox {
      float: left;
      margin-left: 52px;
      .kk {
        margin-bottom: 14px;
      }
    }
    .part8 {
      margin-top: 8px;
    }

    .mustRead {
      font-weight: bold;
      margin: 9px 0 0 362px;
    }
    .gcaz {
      padding-top: 20px;
      box-sizing: border-box;
    }
    .gcaz {
      .el-form-item__content {
        margin-left: 0px !important;
      }
    }
    .hou {
      margin-right: 20px;
    }

    .checkboxml {
      float: left;
      width: 1000px;
      color: #666666;
      font-size: 13px;
    }
    .el-button {
      font-size: 20px !important;
      width: 146px !important;
      height: 50px !important;
    }
    .ktlx {
      width: 20px;
      height: 250px;
      margin: 0 73px 0 20px;
      padding-top: 100px;
      box-sizing: border-box;
      line-height: 28px;
    }

    .pdxtpart > div {
      margin: 15px 0;
    }

    .ktlx1 {
      width: 928px;
      height: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;
    }
    .ktlxtype {
      padding-top: 25px;
      box-sizing: border-box;
    }
    .ktlx2 {
      width: 587px;
      height: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;
    }
    .tdtc {
      margin-left: 150px;
    }
    .qtsm {
      margin-bottom: 20px;
    }
    .gang {
      font-weight: bold;
      margin-left: 160px;
      line-height: 35px;
    }
    .yzbp {
      width: 1048px;
      color: #666666;
      font-size: 14px;
      line-height: 26px;
    }
    .yzbpi {
      width: 1048px;
      color: #666666;
      font-size: 14px;
      margin-left: 10px;
    }
    .jglx {
      margin-left: 2px;
    }
    .lasttext {
      font-size: 14px;
      color: #999999;
      margin-left: -9px;
      margin-bottom: 10px;
    }
    .gcaz {
      .el-checkbox__label {
        font-weight: bold;
      }
    }
    .oip {
      width: 110px !important;
      margin: 0 20px;
    }

  }
}
</style>
